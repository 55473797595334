import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
//import './index.scss';

// Pages
import Home from './pages/home';
import About from './pages/about';
import HowTo from './pages/howto'
import Send from './pages/send';
import Personvern from './pages/personvern';
import Tech from './pages/tech';
import Credits from './pages/credits';
import Category from './pages/category';
import Stats from './pages/stats';

// Components
import Header from './pages/components/header'
import Footer from './pages/components/footer'

//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/darkly/bootstrap.min.css';

import './theme_changes.css'

ReactGA.initialize('UA-155480703-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
       <BrowserRouter>
        <div>
          <Header />
            <Switch>
             <Route path="/" component={Home} exact/>
             <Route path="/om" component={About} exact/>
             <Route path="/hvordan-sette-opp-eget-soundboard" component={HowTo} exact/>
             <Route path="/hvordan" component={HowTo} exact/>
             <Route path="/send" component={Send} exact/>
             <Route path="/personvern" component={Personvern} exact/>
             <Route path="/tech" component={Tech} exact/>
             <Route path="/takk" component={Credits} exact/>
             <Route path="/lastned" component={Home} exact/>
             <Route path="/stats" component={Stats} exact/>
             <Route path="/:catid" component={Category} exact/>
            <Route component={Home}/>
           </Switch>
           <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
