import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';

const Header = () => {
    return (
       <div>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href="/">Soundboard.no</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link href="/">Alle lyder</Nav.Link>
                    <Nav.Link href="/hvordan-sette-opp-eget-soundboard">Guide: Hvordan sette opp eget soundboard</Nav.Link>
                  </Nav>
                  <Nav>
                    <Nav.Link href="/om">Om</Nav.Link>
                    <Nav.Link href="/tech">Tech</Nav.Link>
                    <Nav.Link href="/takk">Takk til</Nav.Link>
                    <Nav.Link href="/stats">Stats</Nav.Link>
                    <Nav.Link href="/personvern">Personvern</Nav.Link>
                  </Nav>
                  <a href="/send"><Button variant="outline-success">Send inn lyder</Button></a>
                </Navbar.Collapse>
              </Navbar>
       </div>
    );
}

export default Header;
