import React from "react";

const Footer = props => {
    return (
    <footer className="footer" >
      <div className="row" style={{  display: 'block', padding: '20px', height: '60px', width: '100%'}}>
        <div className="row" style={{width: '100%', margin: 'auto', position: "relative", textAlign: "center", left: "0", bottom: "0", padding: "20px", height: "60px"}}>
            <div className="col-md-4">
                <p>{(new Date().getFullYear())} © <a href="/om">Observatorietroppen</a></p>
            </div>
            <div className="col-md-4">
                <p><a href="/tech">Tech</a> / <a href="/takk">Takk til</a> / <a href="/stats">Stats</a></p>
            </div>
            <div className="col-md-4">
                <p><a href="/personvern">Personvern</a> / <a href="/personvern">Cookies</a> <span aria-label="" role="img" alt="Kjeks emoji">🍪</span></p>
            </div>
        </div>
      </div>
    </footer>
)};

export default Footer;
