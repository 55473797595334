import React, { Fragment } from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import { Helmet } from "react-helmet";

class Tech extends React.Component {
    render() {
      return (<Fragment>
        <Helmet>
            <title>Soundboard.no - Tech</title>
            <meta name="description" content="Teknologien bak Soundboard.no" />
        </Helmet>
          <Container>
              <Row>
                  <Col>
                     <div>
                       <br></br>
                        <h1>Tech stack</h1>
                        <p>Hello fellow nerd <span aria-label="" role="img" alt="Nerd emoji">🤓</span></p>
                        <p>Vi synes for få sider har info om hvilke teknologier de bruker. Her er hva soundboard.no benytter:</p>
                        <h5>Frontend</h5>
                        <ul>
                          <li><a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a></li>
                          <li><a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap</a> (Rammeverk for design)</li>
                        </ul>
                        <h5>Backend</h5>
                        <ul>
                          <li><a href="https://nodejs.org/" target="_blank" rel="noopener noreferrer">NodeJS</a></li>
                          <li><a href="https://serverless.com/" target="_blank" rel="noopener noreferrer">Serverless framework</a></li>
                          <li><a href="https://www.python.org/" target="_blank" rel="noopener noreferrer">Python</a> (For justering av lyd og automatisk generasjon av nedlastningsfiler, samt generasjon av importfiler til <a href="/hvordan-sette-opp-eget-soundboard" target="_blank" rel="noopener noreferrer">oppsett av eget soundboard</a>)</li>
                        </ul>
                        <h5>Infrastruktur</h5>
                        <p>Soundboard.no kjører alt hos <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">Amazon Web Services</a>. Vi benytter oss av en serverless infrastruktur og er laget slik at vi skal kunne skalere så mye vi ønsker. Løsningen hostes i eu-north-1 (Stockholm) for lavest latency for våre norske brukere. Vi benytter oss av følgende produkter hos AWS:</p>
                        <ul>
                          <li>Frontend: <a href="https://aws.amazon.com/amplify/" target="_blank" rel="noopener noreferrer">AWS Amplify</a></li>
                          <li>Backend: <a href="https://aws.amazon.com/lambda/" target="_blank" rel="noopener noreferrer">AWS Lambda</a></li>
                          <li>Automatisk deployment: <a href="https://aws.amazon.com/codepipeline/" target="_blank" rel="noopener noreferrer">AWS CodePipeline</a></li>
                          <li>Lagring av lyder: <a href="https://aws.amazon.com/s3/" target="_blank" rel="noopener noreferrer">Amazon S3</a></li>
                          <li>Lagring av statistikk: <a href="https://aws.amazon.com/dynamodb/" target="_blank" rel="noopener noreferrer">Amazon DynamoDB</a></li>
                          <li>Logging, monitorering og administrasjon av infrastruktur: <a href="https://aws.amazon.com/cloudwatch/" target="_blank" rel="noopener noreferrer">Amazon ColudWatch</a> & <a href="https://aws.amazon.com/cloudformation/" target="_blank" rel="noopener noreferrer">AWS Cloud​Formation</a></li>
                          <li>API admistrasjon: <a href="https://aws.amazon.com/api-gateway/" target="_blank" rel="noopener noreferrer">Amazon API Gateway</a></li>
                        </ul>
                        <p>Happy coding! <span aria-label="" role="img" alt="Tada emoji">🎉</span></p>
                     </div>
                 </Col>
             </Row>
          </Container>
       </Fragment>
      )
    }
}

export default Tech;
