import React, { Fragment } from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import axios from 'axios';

// PROD
var requesturl = 'https://z7ozwiojl8.execute-api.eu-north-1.amazonaws.com/production/api/v1/';
// DEV
//var requesturl = 'https://co6y8bs1jh.execute-api.eu-north-1.amazonaws.com/development/api/v1/';
// LOCAL
//var requesturl = 'http://localhost:8101/api/v1/';

class Stats extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			statsSongs: 0,
			statsDownload: 0,
			statsShare: 0,
			statsSoundpadDownload: 0,
			statsStreamdeckDownload: 0
        }

		this.fetchStatsSongs();
		this.fetchStatsDownload();
		this.fetchStatsShare();
		this.fetchStatsSoundpadDownload();
		this.fetchStatsStreamdeckDownload();
	}

    fetchStatsSongs() {
      	axios.get(
          	requesturl + 'stats/sounds',
          	{ headers: { 'Content-Type': 'application/json' } },
      	).then(response => {
		  	this.setState({statsSongs: response.data.data})
    }).catch(function (error) {
        	console.log(error.code);
        	console.log(error.message);
        	console.log(error.stack);
        });
	}
	
	fetchStatsDownload() {
		axios.get(
			requesturl + 'stats/download',
			{ headers: { 'Content-Type': 'application/json' } },
		).then(response => {
			this.setState({statsDownload: response.data.data})
	}).catch(function (error) {
			console.log(error.code);
			console.log(error.message);
			console.log(error.stack);
		});
	}

	fetchStatsShare() {
		axios.get(
			requesturl + 'stats/share',
			{ headers: { 'Content-Type': 'application/json' } },
		).then(response => {
			this.setState({statsShare: response.data.data})
	}).catch(function (error) {
			console.log(error.code);
			console.log(error.message);
			console.log(error.stack);
		});
	}

	fetchStatsSoundpadDownload() {
		axios.get(
			requesturl + 'stats/soundpaddownload',
			{ headers: { 'Content-Type': 'application/json' } },
		).then(response => {
			this.setState({statsSoundpadDownload: response.data.data})
	}).catch(function (error) {
			console.log(error.code);
			console.log(error.message);
			console.log(error.stack);
		});
	}

	fetchStatsStreamdeckDownload() {
		axios.get(
			requesturl + 'stats/streamdeckdownload',
			{ headers: { 'Content-Type': 'application/json' } },
		).then(response => {
			this.setState({statsStreamdeckDownload: response.data.data})
	}).catch(function (error) {
			console.log(error.code);
			console.log(error.message);
			console.log(error.stack);
		});
	}

    render() {
      return (<Fragment>
        <Helmet>
            <title>Soundboard.no - Stats</title>
            <meta name="description" content="Statistikk for Soundboard.no" />
        </Helmet>
          	<Container>
			  	<br></br>
                <h1>Stats for Soundboard.no</h1>
				<br></br>
            	<Row>
                	<Col>
						<h1>{this.state.statsSongs}</h1>
						<p>Lyder spilt av via nettsiden</p>
					</Col>
					<Col>
						<h1>{this.state.statsDownload}</h1>
						<p>Lydpakker lastet ned</p>
                	</Col>
					<Col>
						<h1>{this.state.statsShare}</h1>
						<p>Lyder delt via nettsiden</p>
                	</Col>
					<Col>
						<h1>{this.state.statsSoundpadDownload}</h1>
						<p>Soundpad profiler lastet ned</p>
                	</Col>
					<Col>
						<h1>{this.state.statsStreamdeckDownload}</h1>
						<p>Streamdeck profiler lastet ned</p>
                	</Col>
            	</Row>
				<br></br>
				<p>Tallene oppdateres automagisk hver sjette time.</p>
          	</Container>
       </Fragment>
      )
    }
}

export default Stats;
