import React, { Fragment } from 'react';
import { Container, Row, Col, Button  } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import axios from 'axios';

// PROD
var requesturl = 'https://z7ozwiojl8.execute-api.eu-north-1.amazonaws.com/production/api/v1/';
// DEV
//var requesturl = 'https://co6y8bs1jh.execute-api.eu-north-1.amazonaws.com/development/api/v1/';
// LOCAL
//var requesturl = 'http://localhost:8101/api/v1/';

class HowTo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        play: false,
    }
  }

  audioLykkeTil_1 = new Audio('https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/fl%C3%A5klypa/lykke_til.mp3');
  audioLykkeTil_2 = new Audio('https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/vazelina_bilopph%C3%B8ggers/lykke_til.mp3');

  componentDidMount() {
    this.audioLykkeTil_1.addEventListener('ended', () => this.setState({ play: false }));
    this.audioLykkeTil_2.addEventListener('ended', () => this.setState({ play: false }));
  }

  componentWillUnmount() {
      this.audioLykkeTil_1.removeEventListener('ended', () => this.setState({ play: false }));
      this.audioLykkeTil_2.removeEventListener('ended', () => this.setState({ play: false }));
  }

  playLykkeTil = () => {
    this.setState({ play: !this.state.play }, () => {
      if (this.state.play) {
          const rndInt = Math.floor(Math.random() * 2) + 1
          if (rndInt === 1) {
            this.audioLykkeTil_1.play();
            this.logSound('flåklypa/lykke_til.mp3');
          } else {
            this.audioLykkeTil_2.play();
            this.logSound('vazelina_bilopphøggers/lykke_til.mp3');
          }
      } else {
          this.audioLykkeTil_1.pause();
          this.audioLykkeTil_1.currentTime = 0;
          this.audioLykkeTil_2.pause();
          this.audioLykkeTil_2.currentTime = 0;
      }
    });
  }

  logSound(playedSound) {
    axios.post(requesturl + 'log/sound', {
      sound: playedSound,
    }).catch(function (error) {
      console.log(error);
    });
  }

  logSoundpadDownload() {
    axios.post(requesturl + 'log/soundpaddownload', {
    }).catch(function (error) {
      console.log(error);
    });
  }

  logStreamdeck2x3Download() {
    axios.post(requesturl + 'log/streamdeckdownload', {
      profileSize: "2x3",
    }).catch(function (error) {
      console.log(error);
    });
  }

  logStreamdeck3x5Download() {
    axios.post(requesturl + 'log/streamdeckdownload', {
      profileSize: "3x5",
    }).catch(function (error) {
      console.log(error);
    });
  }

  logStreamdeck4x8Download() {
    axios.post(requesturl + 'log/streamdeckdownload', {
      profileSize: "4x8",
    }).catch(function (error) {
      console.log(error);
    });
  }

    render() {
      return (<Fragment>
        <Helmet>
            <title>Soundboard.no - Hvordan sette opp eget soundboard</title>
            <meta name="description" content="Hvordan sette opp eget soundboard" />
        </Helmet>
          <Container>
              <Row>
                  <Col>
                     <div>
                       <br></br>
                        <h1>Hvordan sette opp eget soundboard</h1>
                        <p style={{fontWeight: 'bold'}}> Kort oppsummert: Last ned <a href='https://leppsoft.com/soundpad/en/' target='_blank' rel="noopener noreferrer">Soundpad</a> -{'>'} Last ned Soundpad profilen og åpne denne -{'>'} Endre mikrofonen på din maskin til den virtuelle mikrofonen</p>
                        <p>For å sette opp et soundboard på din egen maskin trenger du programvare som lar deg spille av lyder gjennom mikrofonen din.
                        <br/>Det finnes ulike typer programvare for dette, både betalte og gratis versjoner. Vi som har laget soundboard.no, har slått oss til ro med betaltløsningen <a href='https://leppsoft.com/soundpad/en/' target='_blank' rel="noopener noreferrer">Soundpad</a> som den beste for dette formålet.
                        <br/>Du står fritt til å bruke den programvaren du ønsker. Beskrivelsen tar utgangspunkt i bruk av <a href='https://leppsoft.com/soundpad/en/' target='_blank' rel="noopener noreferrer">Soundpad</a>, men prosessen vil med stor sannsynlighet være lik med annen programvare. Våre ferdige profiler for Soundpad og Streamdeck fungerer kun med <a href='https://leppsoft.com/soundpad/en/' target='_blank' rel="noopener noreferrer">Soundpad</a>.</p>
                        <br/>
                        <h2>Step by step guide (PC):</h2>
                        <p>1. <a href='https://leppsoft.com/soundpad/en/' target='_blank' rel="noopener noreferrer">Last ned Soundpad</a> og installer programvaren.</p>
                        <p>2. Ved oppstart av Soundpad, velg din mikrofon som input og høyttalere/headset som output i innstillinger.</p>
                        <p>3. Gå inn på <a href="/" target='_blank' rel="noopener noreferrer">Soundboard.no</a>, last ned ønskede lyder og pakk ut disse til en mappe på din maskin.</p>
                        <p>4. Last ned Soundpad profilen og hele lydbiblioteket under. Husk å lagre dette på et sted du tenker å ikke flytte det fra.</p>
                        <p>5. Åpne filen som heter #soundlist.spl med Soundpad</p>
                        <p>Alle lydene skal nå ha blitt importert og klare til bruk!</p>
                        <Button onClick={this.logSoundpadDownload} href={'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/scripts/soundboard.zip'} variant='success' style={{margin: '2px'}}>Last ned Soundpad profil</Button>
                        <br/>
                        <br/>
                        <h4>Bonus: Bruk <a href='https://www.elgato.com/en/gaming/stream-deck' target='_blank' rel="noopener noreferrer">Streamdeck</a> som et fysisk soundboard</h4>
                        <p><b>Obs:</b> Forutsetter at du har gjort stegene over.</p>
                        <p>6. Sett opp ditt <a href='https://www.elgato.com/en/gaming/stream-deck' target='_blank' rel="noopener noreferrer">Streamdeck</a> og <a href='https://www.elgato.com/en/gaming/downloads' target='_blank' rel="noopener noreferrer">installer medfølgende programvare</a>.</p>
                        <p>7. Klikk på Stream Deck Store (regnbuetastatur) i Streamdeck-programvaren.</p>
                        <p>8. Installer pluginen Soundpad Integration av BarRaider.</p>
                        <p>9. Last ned passende profil basert på størrelsen på ditt Streamdeck.</p>
                        <p>10. Åpne profilen og velg den som aktiv i Streamdeck-programvaren</p>
                        <p>For best opplevelse anbefaler vi at man restartet Streamdeck helt etter importen er ferdig. Vi anbefaler også at man alltid åpner Soundpad-programvaren før man åpner Streamdeck etter en restart av maskinen.</p>
                        <p>Du skal nå kunne bruke Streamdeck til å spille av lyder gjennom Soundpad-programvaren din!</p>
                        <p onClick={this.playLykkeTil}>Kos deg, og lykke til!</p>
                        <Button onClick={this.logStreamdeck2x3Download} href={'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/scripts/streamdeck3x5.streamDeckProfile'} variant='success' style={{margin: '2px'}}>Last ned Streamdeck 2x3 profil</Button>
                        <Button onClick={this.logStreamdeck3x5Download} href={'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/scripts/streamdeck3x5.streamDeckProfile'} variant='success' style={{margin: '2px'}}>Last ned Streamdeck 3x5 profil</Button>
                        <Button onClick={this.logStreamdeck4x8Download} href={'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/scripts/streamdeck4x8.streamDeckProfile'} variant='success' style={{margin: '2px'}}>Last ned Streamdeck 4x8 profil</Button>
                        <br/>
                        <br/>
                        <p>Lyst til å sende oss lyder du føler mangler på Soundboard.no? <a href="/send">Klikk her!</a>
                        <br/>Vi har ingen reklame på siden fordi vi ønsker at soundboard.no skal være gratis. Om du liker siden og vil slenge over litt kronasj, <a href="/om">klikk gjerne her!</a></p>
                     </div>
                 </Col>
             </Row>
          </Container>
       </Fragment>
      )
    }
}

export default HowTo;
