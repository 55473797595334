import React, { Fragment } from 'react';
import { Container, Row, Col, Modal,  } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import vippsImage from './../vipps.jpg';
import axios from 'axios';

// PROD
var requesturl = 'https://z7ozwiojl8.execute-api.eu-north-1.amazonaws.com/production/api/v1/';
// DEV
//var requesturl = 'https://co6y8bs1jh.execute-api.eu-north-1.amazonaws.com/development/api/v1/';
// LOCAL
//var requesturl = 'http://localhost:8101/api/v1/';

class About extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: false,
            play: false,
        }
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
      }

    openPopup() {
      this.setState({ popup: true });
      this.playVipps();
    }

    closePopup() {
      this.setState({ popup: false })
    }

    openDonationPopup() {
      return (
        <Fragment>
          <Modal show={this.state.popup} onHide={this.closePopup}>
            <Modal.Header closeButton>
              <Modal.Title>De tar vipps!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Jeg lover å dele broderlig med Eirik.
              <img src={vippsImage} style={{height: '250px', width: '250px'}} alt="Vippskode"></img>
              <hr></hr>
              Kaffe fungerer også!<br/>
              <a href="https://www.buymeacoffee.com/Erlpil" target="_blank" rel="noopener noreferrer"><img src="https://cdn.buymeacoffee.com/buttons/default-orange.png" alt="Buy Me A Coffee" style={{height: '51px', width: '217px'}}></img></a>
            </Modal.Body>
          </Modal>
        </Fragment>
      );
    }

    audioKosDere = new Audio('https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/hvite_gutter/kos_dere_a.mp3');
    audioVipps = new Audio('https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/norske_gr%C3%B8nnsaker/de_tar_vipps.mp3');

    componentDidMount() {
        this.audioKosDere.addEventListener('ended', () => this.setState({ play: false }));
        this.audioVipps.addEventListener('ended', () => this.setState({ play: false }));
    }

    componentWillUnmount() {
        this.audioKosDere.removeEventListener('ended', () => this.setState({ play: false }));
        this.audioVipps.removeEventListener('ended', () => this.setState({ play: false }));
    }

    playKosDere = () => {
      this.setState({ play: !this.state.play }, () => {
      if (this.state.play) {
          this.audioKosDere.play();
          this.logSound('hvite_gutter/kos_dere_a.mp3');
      }
      else {
          this.audioKosDere.pause();
          this.audioKosDere.currentTime = 0;
      }
      });
    }

    playVipps = () => {
      this.setState({ play: !this.state.play }, () => {
      if (this.state.play) {
          this.audioVipps.play();
          this.logSound('norske_grønnsaker/de_tar_vipps.mp3');
      }
      else {
          this.audioVipps.pause();
          this.audioVipps.currentTime = 0;
      }
      });
    }

    logSound(playedSound) {
      axios.post(requesturl + 'log/sound', {
        sound: playedSound,
      }).catch(function (error) {
        console.log(error);
      });
    }

    render() {
      return (<Fragment>
        <Helmet>
            <title>Soundboard.no - Om</title>
            <meta name="description" content="Historien til Soundboard.no" />
        </Helmet>
          <Container>
              <Row>
                  <Col>
                     <div>
                       <br></br>
                        <h1>Om Soundboard.no</h1>
                        <p>Soundboard.no er et hobbyprosjekt utviklet av <a href='https://eirikthorsrud.no' target='_blank' rel="noopener noreferrer">Eirik</a> og <a href='https://erlpil.no' target='_blank' rel="noopener noreferrer">Erlend</a>. Vi oppdaget gleden av å benytte lydklipp i gaming og bygget et lite bibliotek. Den siste tiden har flere og flere spurt om å få tilgang til biblioteket og ønsket å dele sine lyder med oss. Grunnet dette skapte vi Soundboard.no. Siden er fremdeles under utvikling. Nye lyder legges til kontinuerlig.</p>
                        <p>Vi har en hyggelig Teamspeak server hvor man kan teste Soundboardet sammen med venner i et lystig lag. Sving gjerne innom! IP: teamspeak.no</p>
                        <p>Lyst til å sende oss en lyd? <a href="/send">Klikk her!</a></p>
                        <p>Annen kontakt: <a href="mailto:hei@soundboard.no">hei@soundboard.no</a></p>
                        <p onClick={this.openPopup}>Reklame er irriterende. Soundboard.no er derfor gratis. Det koster dog noe kronasj å ha tjenesten kjørende.</p> <p style={{textDecorationLine: 'underline', fontWeight: 'bold'}} onClick={this.openPopup}>Om du har lyst til å støtte oss kan du klikke her! (Vipps / Buy me a coffee)</p>
                        <br></br>
                        <p onClick={this.playKosDere}>Kos dere a!</p>
                        <br></br>
                     </div>
                 </Col>
             </Row>
             {this.openDonationPopup()}
          </Container>
       </Fragment>
      )
    }
}

export default About;
