import React, { Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import axios from 'axios';
import SoundButton from './components/soundbutton';

// PROD
var requesturl = 'https://z7ozwiojl8.execute-api.eu-north-1.amazonaws.com/production/api/v1/';
// DEV
//var requesturl = 'https://co6y8bs1jh.execute-api.eu-north-1.amazonaws.com/development/api/v1/';
// LOCAL
//var requesturl = 'http://localhost:8101/api/v1/';

var newSoundThreshold = 30; //Days

class Category extends React.Component {
    constructor(props) {
        super(props);

        var temptitle = this.props.match.params.catid;
        const regex = /_/g;
        temptitle = temptitle.replace(regex, ' ');

        var sounds = [];

        this.state = {
            titleedited: temptitle,
            imageURL: 'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/images/' + this.props.match.params.catid + '.png',
            sounds: sounds
        }

        this.renderBlank = this.renderBlank.bind(this);
        this.fetchData();
    }

    fetchData() {
        axios.get(
            'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/scripts/json_data.json',
            { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } },
        ).then(response => response.data.forEach(cat => {
            if (cat.name === this.props.match.params.catid){
                this.setState({sounds:cat.sounds})
            }
        }))
    }

    logDownload() {
        axios.post(requesturl + 'log/download', {
            file: this.props.title,
        }).catch(function (error) {
            console.log(error);
        });
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    renderBlank() {
        this.setState({
            imageURL: 'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/images/default.png',
        })
    }

    isNew(added) {
        var today = Math.round((new Date()).getTime() / 1000);
        var daysSinceAdded = (today - added) / (3600 * 24)
        if (daysSinceAdded < newSoundThreshold) {
            return true
        } else {
            return false
        }
    }

    render() {
        return (<Fragment>
            <Helmet>
                <title>{this.Capitalize(this.state.titleedited)} - Soundboard.no</title>
                <meta name="description" content={"Soundboard lyder av " + this.state.titleedited} />
            </Helmet>
            <Container>
                <img src={this.state.imageURL} onError={this.renderBlank} style={{ marginLeft: 'auto', marginRight: 'auto', height: 'auto', width: 'auto', maxWidth: '100%' }} alt="Bilde av kategori"></img>
                <Row>
                    <Col>
                        <div>
                            <br></br>
                            <h1>{this.Capitalize(this.state.titleedited)}</h1>
                            <a href={'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/lastned/' + this.props.match.params.catid + '.zip'} style={{ float: 'right', height: '50px', clear: 'both' }}><Button style={{ float: 'right', height: '50px' }} variant="dark" onClick={this.logDownload.bind(this)}>Last ned {this.Capitalize(this.state.titleedited)}</Button></a>
                            {this.state.sounds.map(sound => <SoundButton key={sound.fullName} sound={sound.fullName} isNew={this.isNew(sound.added)}/>)}
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
        )
    }
}

export default Category;
