import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";

class Send extends React.Component {
    render() {
      return (<Fragment>
        <Helmet>
            <title>Soundboard.no - Personvern</title>
            <meta name="description" content="Personvern" />
        </Helmet>
          <Container>
              <Row>
                  <Col>
                     <div>
                       <br></br>
                        <h1>Personvern</h1>
                        <h5>Lagring av data</h5>
                        <p>Soundboard.no lagrer følgende data:</p>
                        <ul>
                          <li>Når lyder spilles av</li>
                          <li>Hvilke lyder som spilles av</li>
                          <li>Når filer lastes ned</li>
                          <li>Hvilke filer som lastes ned</li>
                        </ul>
                        <p>For å beskytte tjenesten kan din IP bli midlertidig lagret for blokkering ved bruk som vi anser som bot eller automatisert adferd. Dette skjer automatisert på proxy nivå. Tjenestene er levert av AWS.</p>
                        <h5>Cookies</h5>
                        <p>Soundboard.no bruker Google Analytics for å analyser brukeradferd og brukermønster.</p>
                        <h5>Opphavsrett</h5>
                        <p>Soundboard.no har ikke opphavsrett på de fleste av lydklippene som vises på siden.</p>
                        <p>Om du opplever at siden har lydklipp du har opphavsrett på og du ønsker den fjernet, ta kontakt med oss på: <a href="mailto:opphavsrett@soundboard.no">opphavsrett@soundboard.no</a></p>
                        <h5>Støtende innhold</h5>
                        <p>Lydklippene som finnes på Soundboard.no reflekterer ikke våre synspunkter eller meninger. Siden og dens innhold er laget for å være humor / underholdning.</p>
                        <p>Om du opplever at et lydklipp har støtende innhold, ta kontakt med oss på: <a href="mailto:report@soundboard.no">report@soundboard.no</a></p>
                     </div>
                 </Col>
             </Row>
          </Container>
       </Fragment>
      )
    }
}

export default Send;
