import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
/* eslint-disable no-unused-expressions */

// PROD
var requesturl = 'https://z7ozwiojl8.execute-api.eu-north-1.amazonaws.com/production/api/v1/';
// DEV
//var requesturl = 'https://co6y8bs1jh.execute-api.eu-north-1.amazonaws.com/development/api/v1/';
// LOCAL
//var requesturl = 'http://localhost:8101/api/v1/';

class Soundbutton extends React.Component {
  constructor(props) {
    super(props);

    var temptitle = this.props.sound;
    const regex = /^[^]*\//gi;
    const regex2 = /\..*$/; /_/g;
    const regex3 = /_/g;
    temptitle = temptitle.replace(regex, '');
    temptitle = temptitle.replace(regex2, '');
    temptitle = temptitle.replace(regex3, ' ');

    this.state = {
      play: false,
      title: temptitle,
    }
  }

  audio = new Audio('https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/' + this.props.sound);

  componentDidMount() {
    this.audio.addEventListener('ended', () => this.setState({ play: false }));
  }

  componentWillUnmount() {
    this.audio.removeEventListener('ended', () => this.setState({ play: false }));
  }

  togglePlay = () => {
    this.setState({ play: !this.state.play }, () => {
      if (this.state.play) {
        this.audio.play();
        this.logSound(this.props.sound);
      }
      else {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    });
  }

  logSound(playedSound) {
    axios.post(requesturl + 'log/sound', {
      sound: playedSound,
    }).catch(function (error) {
      console.log(error);
    });
  }

  logShare() {
    axios.post(requesturl + 'log/share', {
      sound: this.props.sound,
    }).catch(function (error) {
      console.log(error);
    });
  }

  buttonColor() {
    if (this.props.shareMode) {
      return 'primary'
    } else {
      if (!this.state.play) {
        if (this.props.isNew) {
          return 'warning'
        } else {
          return 'success'
        }
      } else {
        return 'danger'
      }
    }
  }

  handleSharing = async () => {
    if (isMobile) {
      if (navigator.share) {
        try {
          const sound = await fetch("https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/" + this.props.sound);
          const blob = await sound.blob();
          const file = new File([blob], this.props.sound, { type: 'audio/mp3' });
  
          await navigator
            .share({ files: [file] })
            .then(() => {
              console.log("Hooray! Your content was shared to tha world")
              this.logShare(this.props.sound)
            });
        } catch (error) {
          console.log(`Oops! I couldn't share to the world because: ${error}`);
        }
      } else {
        // fallback code
        console.log(
          "Web share is currently not supported on this browser. Please provide a callback"
        );
      }
    } else {
      this.logShare(this.props.sound)
    }
    
    /*
      await fetch("https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/"+this.props.sound)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          this.props.sound,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    */
  };

  handleAction = () => {
    if(this.props.shareMode){
      this.handleSharing()
    }else {
      this.togglePlay()
    }
  }

  soundButton() {
    if (isMobile) {
        return <Button onClick={this.handleAction} variant={this.buttonColor()} style={{ margin: '2px' }}>{this.state.title}</Button>
    } else {
      if (this.props.shareMode) {
        return <Button onClick={this.handleSharing} href={"https://soundboard-cdn.s3.eu-north-1.amazonaws.com/sounds/" + this.props.sound} variant={this.buttonColor()} style={{ margin: '2px' }}>{this.state.title}</Button>
      } else {
        return <Button onClick={this.togglePlay} variant={this.buttonColor()} style={{ margin: '2px' }}>{this.state.title}</Button>
      }
    }
  }

  render() {
    return (
      <ButtonGroup>
        {this.soundButton()}
      </ButtonGroup>
    );
  }
}

export default Soundbutton;
