import React from 'react';
import { Accordion, Card, FormText, Button, FormCheck } from 'react-bootstrap';
import SoundButton from '../components/soundbutton';
import axios from 'axios';
import { BoxArrowUpRight, CloudDownload, Search } from 'react-bootstrap-icons';
import {BrowserView, MobileView} from 'react-device-detect';

// PROD
var requesturl = 'https://z7ozwiojl8.execute-api.eu-north-1.amazonaws.com/production/api/v1/';
// DEV
//var requesturl = 'https://co6y8bs1jh.execute-api.eu-north-1.amazonaws.com/development/api/v1/';
// LOCAL
//var requesturl = 'http://localhost:8101/api/v1/';


var newSoundThreshold = 30; //Days

class Soundbulk extends React.Component {
    constructor(props) {
        super(props);

        var temptitle = this.props.title;
        const regex = /_/g;
        temptitle = temptitle.replace(regex, ' ');

        var sounds = [];

        this.state = {
            shareMode: false,
            titleedited: temptitle,
            sounds: sounds
        }
    }

    setSounds() {
        this.setState({ sounds: this.props.sounds })
    }

    logDownload(event) {
        axios.post(requesturl + 'log/download', {
            file: this.props.title,
        }).catch(function (error) {
            console.log(error);
        });
        this.stopToggleEvent(event);
    }

    Capitalize(str) {
        return <a>{str.charAt(0).toUpperCase() + str.slice(1)}</a>;
    }

    stopToggleEvent(e) {
        e.stopPropagation();
    }

    searchIcon() {
        if (this.props.filter.length > 0) {
            return (
                <b style={{ float: 'right' }} ><Search /></b>
            )
        } else return null
    }

    searchResult() {
        if (this.props.filter.length > 0) {
            return (
                <b style={{ float: 'right' }} ><FormText>{this.props.sounds.length}</FormText></b>
            )
        } else return null
    }

    isNew(added) {
        var today = Math.round((new Date()).getTime() / 1000);
        var daysSinceAdded = (today - added) / (3600 * 24)
        if (daysSinceAdded < newSoundThreshold) {
            return true
        } else {
            return false
        }
    }

    newIcon() {
        var someNew = this.props.sounds.map(sound => this.isNew(sound.added)).includes(true)
        if (someNew) {
            return (
                <Button disabled variant="outline-warning" size="sm" style={{ marginLeft: '10px' }}> Nye lyder!</Button>
            )
        } else {
            return null
        }
    }

    shareModeStateFlip = (event) => {
        this.setState({ shareMode: event.target.checked })
    }

    render() {
        return (
            <div>
                <Card style={{ minWidth: "200px" }}>
                    <Accordion key={this.props.filter}>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.setSounds.bind(this)}>
                            {this.Capitalize(this.state.titleedited)}
                            {this.newIcon()}
                            <a style={{ float: 'right', marginLeft: '10px' }} href={'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/lastned/' + this.props.title + '.zip'} onClick={this.logDownload.bind(this)}><CloudDownload /></a>
                            <a style={{ float: 'right', marginLeft: '10px' }} href={'/' + this.props.title} target="_blank" rel="noopener noreferrer"><BoxArrowUpRight onClick={this.stopToggleEvent.bind(this)} /></a>
                            {this.searchResult()}
                            {this.searchIcon()}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <BrowserView>
                                <FormCheck onClick={this.shareModeStateFlip} label="Last ned lyd" id={this.props.title} type="switch" style={{ float: 'right', marginLeft: '10px' }}></FormCheck>
                                </BrowserView>
                                <MobileView>
                                <FormCheck onClick={this.shareModeStateFlip} label="Del lyder" id={this.props.title} type="switch" style={{ float: 'right', marginLeft: '10px' }}></FormCheck>
                                </MobileView>
                                {this.state.sounds.map(sound => <SoundButton key={sound.fullName} sound={sound.fullName} shareMode={this.state.shareMode} isNew={this.isNew(sound.added)} />)}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card>
            </div>
        );
    }
}

export default Soundbulk;
